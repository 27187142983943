<template>
    <v-container 
      fluid
      fill-height
      class="d-flex justify-center align-center"
    >
        <v-card
            elevation="1"
            outlined
            :width="$vuetify.breakpoint.width>1264?'60%':$vuetify.breakpoint.width>960?'90%':'100%'"
            class="d-flex justify-center align-center flex-column"
            :style="$vuetify.breakpoint.width<600?'padding: 10x 5px;':'padding: 50px 30px;'"
        >
            <div class="d-flex justify-center align-center" style="width: 100%; margin-bottom: 30px;">
                <v-card-title class="text-h4 font-weight-medium">SIGN UP</v-card-title>
            </div>
            <div :style="$vuetify.breakpoint.width<600?'width:95%;':''">
                <div
                    :class="$vuetify.breakpoint.width<500?'d-flex flex-column align-end':'d-flex align-baseline justify-center'" 
                    style="position: relative;">
                    <v-text-field
                        v-model="email"
                        label="Email"
                        persistent-placeholder
                        clearable
                        style="width: 400px;"
                        prepend-icon="mdi-email"
                        :rules="emailRules"
                        :disabled="chkEmailDup"
                        @keypress.enter="emailvalidation"
                    ></v-text-field>
                    <div v-if="$vuetify.breakpoint.width<500" class="d-flex flex-row align-center">
                        <div v-if="!chkEmailDup && !isCheckBtn && isEmailChecked" style="color: #ff5252; font-size: 12px;">
                            This email is duplicated
                        </div>
                        <v-btn outlined small v-if="!chkEmailDup" color="blue-grey darken-2" @click="emailvalidation" style="margin-left: 10px;">
                            check
                        </v-btn>
                    </div>
                    <div v-if="!chkEmailDup && !isCheckBtn && isEmailChecked && $vuetify.breakpoint.width>=500" style="color: #ff5252; font-size: 11px;">
                        This email is duplicated
                    </div>
                    <v-btn outlined small v-if="!chkEmailDup && $vuetify.breakpoint.width>=500" color="blue-grey darken-2" @click="emailvalidation" style="margin-left: 10px;">
                        check
                    </v-btn>
                    <v-btn 
                        icon 
                        small 
                        color="green darken-3" 
                        v-if="chkEmailDup" 
                        style="margin-left: 10px;position: absolute; right: -40px; bottom: 25px;"
                        :style="$vuetify.breakpoint.width<600?'right: -30px;':''"    
                    >
                        <img id="checkBtn" src="@/assets/check.svg">
                    </v-btn>
                </div>
                <div 
                    v-if="chkEmailDup && !isCheckBtn" 
                    class="d-flex flex-column align-baseline justify-center"
                    style="padding-left: 35px; position: relative;"
                    :style="$vuetify.breakpoint.width<600?'width:100%;':''"
                >
                    <div class="text-caption" style="line-height: 1.2em;">
                        An email has been sent for email authentication. <br> Please check the email for authentication.
                    </div>
                    <div style="color: black; margin: 10px 5px; margin-bottom: 0px; font-size: 11px;">
                        Email Verification
                    </div>
                    <div 
                        :class="$vuetify.breakpoint.width<600?'d-flex flex-column justify-left':'d-flex flex-row justify-left align-baseline'"

                    >
                        <v-text-field
                            style="width: 235px;"
                            :style="$vuetify.breakpoint.width<600?'width:100%;':''"
                            filled
                            dense
                            hide-details
                            :disabled="isSubmit"
                            v-model="vNum"
                            @keypress.enter="numValid"
                            placeholder="Enter Verification Code"
                        ></v-text-field>
                        <v-btn 
                            outlined 
                            small 
                            v-if="!isSubmit && !isTimeOut" 
                            @click="numValid" 
                            color="blue-grey darken-2" 
                            style="margin: 2px 10px;"
                            :style="$vuetify.breakpoint.width<600?'margin: 5px 0px !important;':''"
                        >
                            check
                        </v-btn>
                        <v-btn
                        outlined
                        small
                        :disabled="isResendDisabled"
                        v-if="isTimeOut"
                        color="blue-grey darken-2"
                        @click="emailvalidation"
                        style="margin-left: 10px;"
                        :style="$vuetify.breakpoint.width<600?'margin: 5px 0px !important;':''"
                        >
                        Resend
                        </v-btn>
                        <div 
                            :v-model="timer" 
                            v-if="!isSubmit && !isTimeOut" 
                            style="margin-top: 4px; color:red;"
                            :style="$vuetify.breakpoint.width<600?'margin: 0px !important;':''"
                        >
                            {{ timer }}
                        </div>
                        <!-- <div v-if="isTimeOut" style="color:red; font-size: 11px; position:relative; top: 38px; right: 130px">
                            Time has expired, please send again. emil.nigay@wikisecurity.net
                        </div> -->
                        <v-btn icon small color="green darken-3" v-if="isSubmit" style="margin-left: 10px; position: absolute; right: -7px; bottom: 30px;">
                            <img id="checkBtn" src="@/assets/check.svg">
                        </v-btn>
                    </div>
                    <div ref="verMess" style="color: #ff5252; font-size: 11px;"></div>
                    <div ref="verTime" style="color: #ff5252; font-size: 11px;"></div>
                </div>
                <div class="inputWrap" :style="$vuetify.breakpoint.width<600?'width:100%;':''">
                    <v-text-field
                        v-model="username"
                        :rules="[() => !!username && username.trim().length > 0 || 'Username is required',]"
                        label="Username"
                        persistent-placeholder
                        clearable
                        prepend-icon="mdi-account"
                    ></v-text-field>
                </div>
                <div class="inputWrap" :style="$vuetify.breakpoint.width<600?'width:100%;':''">
                    <v-text-field
                        v-model="password"
                        label="Password"
                        :rules=pwdRules
                        persistent-placeholder
                        clearable
                        prepend-icon="mdi-lock"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show1 = !show1"
                        :type="show1 ? 'text' : 'password'"
                        @input="pwdValidation"
                    ></v-text-field>
                </div>
                <div 
                    class="d-flex align-baseline justify-center"
                    style="padding-left: 30px; width: fit-content; position: relative;"
                    :style="$vuetify.breakpoint.width<600?'width:100%;':''"    
                >
                    <v-text-field
                        v-model="chkPassword"
                        label="Check Password"
                        persistent-placeholder
                        clearable
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show2 = !show2"
                        style="width: 370px;"
                        :type="show2 ? 'text' : 'password'"
                        :rules="[pwdValidation]"
                    ></v-text-field>
                    <v-btn 
                        icon 
                        small 
                        color="green darken-3" 
                        v-if="chkPwd" 
                        style="margin-left: 10px;position: absolute; right: -40px; bottom: 25px;"
                        :style="$vuetify.breakpoint.width<600?'right: -30px;':''"
                    >
                        <img id="checkBtn" src="@/assets/check.svg">
                    </v-btn>
                </div>
                <div 
                    class="d-flex justify-center"
                    style="gap: 5px; margin-top: 20px;"
                    :style="$vuetify.breakpoint.width<600?'width:90%;':''"
                >
                    <v-btn small elevation="" outlined color="error" :to="'/'">
                        cancel
                    </v-btn>
                    <v-btn small elevation="" :disabled="!isActive" outlined color="green darken-3" @click="signup">
                        sign up
                    </v-btn>
                </div>
            </div>
        </v-card>
    </v-container>
</template>

<script>
// import { address } from '@/mixins/api';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name:'SignUp',
    data:()=>({
        isSend:false,
        isSubmit:false,
        vNum:null,
        email:null,
        show1: false,
        show2: false,
        username:null,
        password:null,
        chkPassword:null,
        chkEmailDup:false,
        chkPwd:false,
        isCheckBtn:true,
        isEmailChecked: false,
        isActive:false,
        timeInterval:null,
        isTimeOut:false,
        isResendDisabled: false,
        timer:null,
        min:0,
        sec:0,
        emailRules: [
            v => !!v || 'Email is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        pwdRules:[
            (v) => !!v || "Password is required",
            (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
            (v) => (v && v.trim().length > 0) || "Passwords must be valid",
            (v) => (v && /[!@#$%^&*(),.?":{}|<>]/.test(v)) || "Password must contain at least one special character",
            (v) => (v && /\d/.test(v)) || "Password must contain at least one number"
        ]
    }),
    computed:{
        isEValid(){
            return this.emailRules.every(rule=>rule(this.email)===true);
        }
    },
    watch:{
        chkEmailDup:{
            handler(){
                this.checkAllFilled();
            },
            immediate:true
        },
        username:{
            handler(){
                this.checkAllFilled();
            },
            immediate:true
        },
        chkPwd:{
            handler(){
                this.checkAllFilled();
            },
            immediate:true
        },
        isSubmit:{
            handler(){
                this.checkAllFilled();
            },
            immediate:true
        },
        email() {
            this.isEmailChecked = false;
            this.chkEmailDup = false;
            this.isEmailValid = true;
        }
        
    },
    methods:{
        sendVer:function(){
            axios.post(`${this.$apiUrl}/user/sendverifymail`,{
                email: this.email
            }).then((res)=>{
                if(res.data.result){
                    this.chkEmailDup=true;
                    this.isCheckBtn=false;
                    this.resetTimer();
                }else{
                    alert("이메일 요청 실패");
                }
            })
        },
        numValid:function(){
            console.log(this.vNum);
            axios.post(`${this.$apiUrl}/user/verifycode`,{
                email: this.email,
                verifycode: this.vNum
            }).then((res)=>{
                if(res.data.result){
                    this.isSubmit=true;
                    this.$refs.verMess.innerHTML=null;
                    // alert("이메일 인증 완료");
                }else{
                    // alert("이메일 인증 실패");
                    this.$refs.verMess.innerHTML='Check your verification email and Try again';
                }
            })
        },
        checkAllFilled:function(){
            if(this.isSubmit && this.username!==null && this.chkPwd){
                this.isActive=true;
            }else{
                this.isActive=false;
            }
        },
        emailvalidation:function(){
            if(this.isEValid){
                this.startTimer();
                axios.post(`${this.$apiUrl}/user/chkemaildup`,{
                    email:this.email
                }).then((res)=>{
                    this.isEmailChecked = true;
                    // console.log(res);
                    if(!res.data.dup_email){ // 이메일이 중복되지 않은 경우
                       this.sendVer();
                    }else{ // 이메일이 중복된 경우
                        this.chkEmailDup=false;
                        this.isCheckBtn=false;
                    }
                })
            }else{
                console.log("email check");
            }
        },
        pwdValidation:function(){
            if(this.chkPassword!=null && this.chkPassword.trim().length > 0){

                if(this.password !=this.chkPassword){
                    this.chkPwd=false;
                    return 'Password do not match';

                }else if(this.password !=this.chkPassword ||
                 this.password.length < 8 || 
                 !/[!@#$%^&*(),.?":{}|<>]/.test(this.password)||
                 !/\d/.test(this.password)
                ){
                
                    this.chkPwd=false;
                    return false;
                }else{
                    this.chkPwd = true;
                    return true;
                }
            }else{
                this.chkPwd=false;
                return 'Check Password is required';
            }
            
        },
        signup:function(){
            if(this.isSubmit && this.username.length !==0 && this.chkPwd){
                console.log("signup")
                axios.post(`${this.$apiUrl}/user/create`,{
                    id: this.username,
                    pw: this.password,
                    email: this.email
                }).then((res)=>{
                    console.log(res);
                    if(res.data.result){
                        console.log("signup finish")
                        Swal.fire({
                            title: 'You have successfully completed your membership.',
                            text: "Please log in again",
                            icon: 'success',
                            width: '400px',
                            confirmButtonText:'OK',
                            confirmButtonColor: '#4CAF50',
                            customClass:{
                                confirmButton: 'custom-confirm-button'
                            }
                        }).then((result)=>{
                                if(result.isConfirmed){
                                    this.$router.push('/login');
                                }
                            });
                    }else{
                        if(res.data.result=="email Verify check failed"){
                            Swal.fire({
                                title: 'Please check your verify code!',
                                icon: 'error',
                                width: '400px',
                                confirmButtonText:'OK',
                                confirmButtonColor: '#FF9800',
                                customClass:{
                                    confirmButton: 'custom-confirm-button'
                                }
                            }).then((result)=>{
                                if(result.isConfirmed){
                                    this.$router.go(this.$router.currentRoute);
                                }
                            });
                        }else if(res.data.result=="Password length must be more 8 characters"){
                            Swal.fire({
                                title: 'Password length must be more 8 characters!',
                                icon: 'error',
                                width: '400px',
                                confirmButtonText:'OK',
                                confirmButtonColor: '#FF9800',
                                customClass:{
                                    confirmButton: 'custom-confirm-button'
                                }
                            }).then((result)=>{
                                if(result.isConfirmed){
                                    this.$router.go(this.$router.currentRoute);
                                }
                            });
                        }
                    }
                })
            }else{
                console.log("information error");
            }
        },
        //이메일 인증 보내면 3분 타이머가 실행, 시간 다 되면 해당 메시지 표시, 다시 보낼 수 있는 기능 제공
        startTimer() {
            this.isResendDisabled = true;
            this.min = 2;
            this.sec = 60;
            this.isTimeOut = false;
            clearInterval(this.timeInterval); 
            this.timeInterval = setInterval(() => {
                if (this.sec === 0) {
                    this.sec = 59;
                    this.min--;
                } else {
                    this.sec--
                }
                this.timer = `${this.min.toString().padStart(2, "0")}:${this.sec.toString().padStart(2, "0")}`;
                if (this.sec === 0 && this.min === 0 && this.isSubmit === false) { 
                    this.isTimeOut = true;
                    this.isResendDisabled = false;
                    if(this.$refs.verMess){
                        this.$refs.verMess.innerHTML=null;
                    }
                    if(this.$refs.verTime){
                        this.$refs.verTime.innerHTML = "Time has expired, please send the code again.";
                    }
                    clearInterval(this.timeInterval);
                }
                else{
                    if(this.$refs.verTime){
                       this.$refs.verTime.innerHTML = null; 
                    }
                }
            }, 1000);
        },
        //시간 다 되면 값 제거 다시 타이머 실행
        resetTimer: function() {
            clearInterval(this.timeInterval);
            this.isTimeOut = false; 
            this.startTimer(); 
        }
    }
}
</script>

<style scoped>
.inputWrap{
    width: 400px; 
    display: flex; 
    align-items: baseline;
    justify-content: left;
}
#checkBtn{
    filter: invert(39%) sepia(11%) saturate(2527%) hue-rotate(73deg) brightness(99%) contrast(91%);
}
#failedBtn{
    filter: invert(30%) sepia(81%) saturate(2254%) hue-rotate(344deg) brightness(102%) contrast(91%);
}



</style>