<template>
  <v-card
  id="top"
  elevation="0"
  :class="['scrollbar', showCloseButton ? 'popup' : 'pa-1']"
  style="height: 100%; overflow-y: scroll; position: relative;"
  >
    <div v-if="$vuetify.breakpoint.width>=500" style="width: 100% ; gap: 7px;" class="d-flex align-center">
      <div class="text-h6 mb-1">
        {{ scanUrl }}
      </div>
      <div class="boxLayout" style="color: #B71C1C; background-color: #FFEBEE; text-transform: uppercase;">
        {{ protocol }}
      </div>
      <div class="boxLayout" style="color:#212121; background-color: #EEEEEE;">
        Port {{ portNum }}
      </div>
      <div v-if="showCloseButton" style="font-size: 0.8rem; line-height: 1.1; position: absolute; right: 20px; ">
        Date: {{ startTime }} <br>
        Time Taken: {{ timeTaken }}
      </div>
      <div v-else style="font-size: 0.8rem; line-height: 1.1; position: absolute; right: 20px; ">
        Date: {{ formatDateTime(startTime) }} <br>
        Time Taken: {{ timeTaken }}
      </div>
    </div>
    
    <div class="d-flex flex-row align-center justify-center pa-2 mb-3" style="height: 80px;">
      <v-card class="d-flex flex-row align-center pa-2" outlined elevation="1" style="width: 25%;">
        <div style="width: 60%;">
          <div style="font-size: 0.8rem;">Risk Score</div>
          <div style="font-size: 20px; font-weight: bold; padding-left: 10px;">{{ findings }}</div>
        </div>
        <div style="width: 40%" class="d-flex align-center justify-end">
          <img style="width: 25px;" id="riskScore" src="@/assets/alert-outline.svg">
        </div>
      </v-card>
      <div class="d-flex flex-row align-center justify-center" style="gap: 10px; width: 75%; height: 90%;">
        <div v-for="(vuln, idx) in vulnLevel1" :key="idx" style="height: 100%; width: 15%;">
          <div v-if="vuln.level=='Well-Known' || vuln.level=='Malware'" :class="[vuln.level, 'd-flex flex-column align-center levelBox']">
            <div v-if="vuln.level=='Well-Known'" style="height: 40%; font-weight: 500;">WellKnown</div>
            <div v-else style="height: 40%; font-weight: 500;">{{vuln.level}}</div>
            <div style="font-weight: 500;">{{ vuln.cnt }}</div>
          </div>
          <div v-else :class="[vuln.level, 'd-flex flex-row levelBox']">
            <div v-if="vuln.level=='Informational'" style="width: 60%; text-align: left; font-weight: 500;">Info</div>
            <div v-else style="width: 60%; text-align: left; font-weight: 500;">{{ vuln.level }}</div>
            <div style="width: 40%;text-align: right; font-weight: 500;">{{ vuln.cnt }}</div>
          </div>
        </div>
      </div>
    </div>
    <v-card 
      elevation="0"
      style="position: relative;"
      :style="$vuetify.breakpoint.width<500?'padding:5px !important;':''"
    >
      <div class="text-h6 mb-2">
        Vulnerabilities Found
      </div>
      <div style="width: 100%">
        <v-card v-if="resultData.length == 0">
          <v-card-text>There's no Vulnerabilities</v-card-text>
        </v-card>
        <div v-if="resultData.length">
          <v-expansion-panels
          v-for="(data, idx) in resultData"
          :key="idx"
          multiple
          focusable
          >
            <v-expansion-panel 
            v-for="(item, i) in data"
            :key="i" 
            :style="{ borderLeft: '4px solid ' + getColor(item.risk)}"
            style="margin-bottom: 5px;"
            >
              <v-expansion-panel-header expand-icon=""  >
                <v-row
                v-if="item.risk!=='Malware' && item.risk !=='Well-Known'"
                >
                  <v-col class="d-flex flex-row align-baseline" cols="10" style="padding: 0px 20px !important;">
                    <div class="text-h6"> {{ vulnName(item.alert) }}</div>
                    <div class="ml-2 text-caption">Instances Found: {{ item.cnt }}</div>
                  </v-col>
                  <v-col cols="2" class="d-flex align-center justify-center pa-0">
                    <div class="risk" :class="item.risk">{{changeName(item.risk)}}</div>
                  </v-col>
                </v-row> 
                <v-row
                v-if="item.risk.toLowerCase()=='malware'"
                >
                  <v-col cols="10" class="d-flex flex-row align-baseline"  style="padding: 0px 20px !important;">
                    <div class="text-h6"> Malware </div>
                    <div class="ml-2 text-caption">Found in {{ item.foundInst.length }} of {{ item.cnt }}</div>
                  </v-col>
                  <v-col cols="2" class="d-flex align-center justify-center pa-0">
                    <div class="risk" :class="item.risk">{{changeName(item.risk)}}</div>
                  </v-col>
                </v-row>
                <v-row
                v-if="item.risk=='Well-Known'"
                >
                  <v-col cols="10" class="d-flex flex-row align-baseline"  style="padding: 0px 20px !important;">
                    <div class="text-h6">{{item.alert}} </div>
                    <div class="ml-2 text-caption">Instances Found: {{ Object.keys(item.validInst).length }}</div>
                  </v-col>
                  <v-col cols="2" class="d-flex align-center justify-center pa-0">
                    <div class="risk" :class="item.risk">{{changeName(item.risk)}}</div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content
              v-if="item.risk!=='Malware' && item.risk !=='Well-Known'"
              style="height: 450px; padding: 20px 0px;"
              ref="resultChild"
              >
                <div style="overflow: auto; height: 100%;">
                  <v-card
                  class="pa-4 ma-3"
                  elevation="0"
                  >
                    <v-card-title class="pa-0">Description</v-card-title>
                    <v-divider style="margin-bottom: 10px;"></v-divider>
                    <div
                    class="pa-4"
                    style="border-radius: inherit; background-color: #f8f9fa; border-color: transparent;"
                    >
                      <div v-html="changeMarkdown(item.description)" class="mb-3" style="line-height: 1.6;"></div>

                      <div v-if="changeTags(item.tags).length>0">
                        <div class="ref-title">OWASP Top 10</div>
                        <ul>
                          <li v-for="(tag, idx) in changeTags(item.tags)" :key="idx"><a style="font-size: 0.8em;" :href="tag.url">{{tag.id}} (2021)-{{tag.title}}</a></li>
                        </ul>
                      </div>

                      <div v-if="cweid(item.cweid).title != '0'">
                        <div class="ref-title">Common Weakness Enumeration (CWE)</div>
                        <ul>
                          <li><a style="font-size: 0.8em;"  :href="cweid(item.cweid).url">{{ cweid(item.cweid).title }}</a></li>
                        </ul>
                      </div>

                      <div v-if="cweid(item.wascid).title !== '0'">
                        <div class="ref-title">Web Application Security Consortium (WASC)</div>
                        <ul>
                          <li><a style="font-size: 0.8em;" class="text-caption" :href="cweid(item.wascid).url">{{ cweid(item.wascid).title }}</a></li>
                        </ul>
                      </div>

                    </div>
                  </v-card>

                  <v-card
                  class="pa-4 ma-3 content"
                  elevation="0"
                  >
                    <v-card-title class="pa-0">Found Instance ({{ item.instance.length }})</v-card-title>
                    <v-divider style="margin-bottom: 10px;"></v-divider>
                    <div v-for="(inst, index) in item.instance" :key="index" class="pa-4 mb-2" style="border-radius: inherit; background-color: #f8f9fa; border: 1px solid #dee2e6; font-size: 0.9em;">
                      <span style="color: #28a745;">({{ inst.method }})</span> <span style="color: #0d6efd">{{ inst.url }}</span> <br>
                      PARAMS: {{ inst.param }}
                    </div>
                  </v-card>
  
                  <v-card
                  class="pa-4 ma-3 content"
                  elevation="0"
                  >
                    <v-card-title class="pb-0 pl-0">Solution</v-card-title>
                    <v-divider></v-divider>
                    <div v-html="changeMarkdown(item.solution)"></div>
                  </v-card>
  
                  
                  <v-card
                  class="pa-4 ma-3 content"
                  elevation="0"
                  >
                    <v-card-title class="pb-0 pl-0">Examples</v-card-title>
                    <v-divider></v-divider>
                    <div v-html="changeMarkdown(item.examples)"></div>
  
                  </v-card>
                  
                  <v-card
                  class="pa-4 ma-3 content"
                  elevation="0"
                  >
                    <v-card-title class="pb-0 pl-0">Other Information</v-card-title>
                    <v-divider class="mb-2"></v-divider>
                    <div v-html="changeMarkdown(item.other)"></div>
  
                  </v-card>
                  
                  <v-card
                  class="pa-4 ma-3 content"
                  elevation="0"
                  >
                    <v-card-title class="pb-0 pl-0">References</v-card-title>
                    <v-divider class="mb-2"></v-divider>
                    <div v-html="changeMarkdown(item.reference)"></div>
  
                  </v-card>

                </div>

              </v-expansion-panel-content>

              <v-expansion-panel-content
                v-if="item.risk.toLowerCase()==='malware'"
                style="max-height: 450px; padding: 20px 0px;"
                ref="resultChild"
              >
                <div style="overflow: auto; height: 100%;">
                  <v-card
                  class="pa-4 ma-3 content"
                  elevation="0"
                  v-if="item.foundInst.length > 0 && item.foundInst[0].description.startsWith('In Demo version,')"
                  >
                    <v-card-title class="pa-0">Instance Found ({{ item.foundInst.length }})</v-card-title>
                    <v-divider class="mb-2"></v-divider>
                    <div class="mb-3" style="line-height: 1.6;">{{ item.foundInst[0].description }}</div>
                  </v-card>
                  <v-card
                  class="pa-4 ma-3 content"
                  elevation="0"
                  v-else
                  >
                    <v-card-title class="pa-0">Instance Found ({{ item.foundInst.length }})</v-card-title>
                    <v-divider class="mb-2"></v-divider>
                    <div
                    class="pa-4"
                    style="border-radius: inherit; background-color: #f8f9fa; border-color: transparent;"
                    v-for="(inst, k) in item.foundInst"
                    :key="k" 
                    >
                      <strong>URL:</strong> {{ inst.url }} <br>
                      <strong>Description:</strong> {{ inst.description }} <br>
                      <strong>Solution:</strong> {{ inst.solution }}
                    </div>
                    <div class="ma-2 mb-0" @click="view = !view" style="cursor: pointer; text-decoration: underline; color: #555;">
                      View Not Found Instances
                    </div>
                    <v-card
                    v-if="view"
                    class="pa-4"
                    style="border-radius: inherit; background-color: #f8f9fa; border-color: transparent;"
                    elevation="0"
                    >
                      <div
                      v-for="(ins, l) in item.instance"
                      :key="l"
                      >
                        {{ ins.url }}
                      </div>
                    </v-card>
                  </v-card>
                </div>
              </v-expansion-panel-content>

              <v-expansion-panel-content
                v-if="item.risk.toLowerCase()==='well-known'"
                style="max-height: 450px; padding: 20px 0px;"
                ref="resultChild"
              >
                <div style="overflow: auto; height: 100%;">

                  <v-card
                  class="pa-4 ma-3 content"
                  elevation="0"
                  >
                    <div
                    class="pa-4"
                    v-for="(value, key) in item.validInst"
                    :key="key" 
                    >
                      <strong>URL:</strong> {{ key }} <br><br>
                      <div
                      class="pa-2 mt-1 d-flex flex-column"
                      outlined
                      style="border-radius: inherit; background-color: #f8f9fa; border-color: transparent; gap: 20px;"
                      >
                        <v-simple-table
                        dense
                        v-for="(content, id) in value" :key="id"
                        style="border: thin solid rgba(0, 0, 0, 0.12);"
                        >
                          <tbody>
                            <tr>
                              <td><strong>Name</strong></td>
                              <td>{{ yaraName(content.name) }}</td>
                            </tr>
                            <tr>
                              <td><strong>Description</strong></td>
                              <td>{{ content.description }}</td>
                            </tr>
                            <tr>
                              <td><strong>Reference</strong></td>
                              <td>{{ content.reference }}</td>
                            </tr>
                            <tr>
                              <td><strong>Solution</strong></td>
                              <td>{{ content.solution }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </div>
<!--                          
                        <div><strong>Name:</strong>{{ content.match_list[0] }}</div>
                        <div><strong>Description:</strong> {{ content.desc }} </div>
                        <div><strong>Reference:</strong> {{ content.reference }}</div>
                        <div><strong>Solution:</strong> {{ content.solution }}</div> -->
                    
                      <v-divider class="mb-2 mt-2"></v-divider>
                    </div>
                   
                  </v-card>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </v-card>

    <v-btn
      v-if="showCloseButton"
      icon
      :outlined="$vuetify.breakpoint.width<600?true:false"
      :text="$vuetify.breakpoint.width<600?true:false"
      :elevation="$vuetify.breakpoint.width<600?'1':''"
      @click="$emit('close')"
      style="position: absolute; top: 5px; right: 10px"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
</v-card>
</template>

<script>
// import horizontalChart from "./horizontalChart.vue";
import { marked } from "marked";
import {owasp} from "@/assets/owasp.json"
import hljs from "highlight.js";
// import { markedHighlight } from "marked-highlight";
import "highlight.js/styles/github.css";

export default {
  components: {
    // horizontalChart,s
  },
  name: "scanResult",
  props: {
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    isHistoryPage: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Array,
      default: () => [],
    },
    timeTaken: {
      type: String,
      default: "0",
    },
    timelines: {
      type: Array,
      default: () => [],
    },
    startTime: {
      type: String,
      defalut: '0'
    },
    scanurl:{
      type: String,
      default:'N/A'
    }
  },
  data: () => ({
    yaraInstance:[],
    resultData: [],
    protocol:null,
    portNum: 0,
    malwareCnt: 0,
    allMalwares: 0,
    cryptoCnt: 0,
    allCrypto: 0,
    totalFindgins: 0,
    view:false,
    view1:false,
    owaspArr:owasp
  }),
  computed: {
    display(){
      var display=false;
      switch(this.$vuetify.breakpoint.name){
        case `lg`: display=true; break;
        case `xl`: display=true; break;
      }
      return display;
    },
    scanUrl() {
      const fullUrl = this.scanurl || "N/A";
      try {
        const urlObj = new URL(fullUrl);
        this.createProtocol(urlObj);
        return urlObj.host;
      } catch (e) {
        console.warn("Invalid URL:", fullUrl);
        return "N/A";
      }
    },
    findings() {
      let total = 0;
      this.vulnLevel1.forEach((x)=>{total += x.cnt})
    return total
    
   
    },
    vulnLevel1() {
      const levels = ["High", "Medium", "Low", "Informational", "Malware", "Well-Known"];
      const result = Array.isArray(this.resultData) ? this.resultData : [];
      const res = levels.map((x)=>{
        return {
          level: x,
          cnt:0,
          color: this.getColor(x)
        }
      })
      result.forEach((x)=>{
        if(x[0].risk == "Malware"){
          const idx = res.findIndex(item => item.level == x[0].risk);
          if(idx != -1){
            x.forEach((y)=>{
              res[idx].cnt+=y.foundInst.length;
            })
          }
        }else if(x[0].risk == "Well-Known"){
          const idx = res.findIndex(item => item.level == x[0].risk);
          if(idx != -1){
            res[idx].cnt = this.yaraInstance.length;
          }
        }else{
          const idx = res.findIndex(item => item.level == x[0].risk);
          if(idx != -1){
            x.forEach((y)=>{
              res[idx].cnt+=y.instance.length;
            })
          }
        }
      })

    console.log(res);
      return res;
    },
  },
  mounted(){
    marked.setOptions({
        renderer: new marked.Renderer(),
        gfm: true,
        headerIds: false,
        tables: true,
        breaks: true,
        langPrefix: 'hljs language-',
        highlight:function(code, lang){
          const validLanguage = lang && hljs.getLanguage(lang) ? lang : 'plaintext';
          return hljs.highlight(code, { language: validLanguage }).value;
        },
        pedantic: false,
        sanitize: false,
        smartLists: true,
        smartypants: false
      });
  },
  methods: {
    yaraName:function(text){
      console.log(text);
      const arr = text.replace(/\[|\]/g, '').split(',').filter(item => item);   
      console.log(arr);
      return arr[0]
    },
    changeTags(tags){
      console.log(tags);
      const jsonObj = JSON.parse(tags);

      const filtered = Object.fromEntries(
        Object.entries(jsonObj).filter((i)=> i[0].startsWith("OWASP_2021"))
      );
      const arr= [];
      console.log(filtered);
      Object.keys(filtered).forEach(key => {
        const modifiedKey = key.substring(key.lastIndexOf("_")+1);
        const matchedItem = this.owaspArr.find(item => item.id == modifiedKey);

        if(matchedItem){
          arr.push({
            ...matchedItem,
            url: filtered[key]
          });
        }
      })
      console.log(arr)
      return arr;
    },
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };
      return date.toLocaleDateString("en-US", options);
    },
    createProtocol:function(url){
      this.protocol=url.protocol.substring(0,url.protocol.length-1);
      console.log(url.protocol.substring(0,url.protocol.length-1))
      this.portNum= url.port != '' ? url.port : this.protocol == "http"? '80' : '443' 
    },
    cweid: function(cwe){
      const arr= cwe.split("<br>");
      const res ={ title: arr[0], url: arr[1] };
      
      console.log(res)
      return res;
    },
    vulnName:function(vuln){
      console.log("vuln");
      console.log(vuln);
      const vulnArr=vuln.split("<br>");
      return vulnArr[vulnArr.length-1];
    },
    changeMarkdown:function(text){
      // console.log(text);
      return marked(text);
    },
    goTop:function(){
        document.getElementById("top").scrollTo({top:0, behavior:'smooth'});
    },
    calrow:function(desc){
      var arr=this.cryptoDesc(desc);
      return 2+arr.length;
    },
    cryptoDesc:function(desc){
      const text = 'In Demo version, If Target site has over 100 resource then Well-Known scan not Execute';
      console.log(desc);
      if(desc != text){
        var desc1= desc.replace(/'/g, '"');
        desc1 = desc1.replace(/(\{|\s)([A-Za-z_][A-Za-z0-9_]*)(:)/g, '$1"$2"$3');
        desc1 = desc1.replace(/(\[|\s)([A-Z_][A-Za-z0-9_]*)(\]|,)/g, '$1"$2"$3');
        desc1 = desc1.replace(/\n/g, '');
  
        var cnt = 0;
        const descArr= JSON.parse(desc1);
        const finalArr = descArr.reduce((acc, item)=>{
          if(!acc[item.category]){
            acc[item.category] = [];
          }
          acc[item.category].push(item);
          cnt++;
          return acc;
        },{});
        // match_list index 확인
        console.log(descArr);
        console.log(finalArr);
        
        return { data: finalArr, count: cnt};
        
      }else{
        console.log(1)
        return 'text';
      }
    },
    getColor(level) {
      switch (level) {
        case "High":
          return "#B71C1C";
        case "Medium":
          return "#E65100";
        case "Low":
          return "#F57F17";
        case "Information":
          return "#0D47A1";
        case "Informational":
          return "#0D47A1";
        case "Malware":
          return "#212121";
        case "Well-Known":
          return "#311B92"
        default:
          return "#FFFFFF";
      }
    },
    //Informatinal risk 이름 Information으로 바뀜
    changeName(name) {
      const newName = name === "Informational" ? "Information" :name;
      return newName.charAt(0).toUpperCase() + newName.slice(1);
    },
  },
  watch: {
    result: {
      handler(newVal) {
        // if (!Array.isArray(newVal)) {
        //   console.error("newVal is not an array:", newVal);
        //   return;
        // }
        // console.log("result newVal");
        this.resultData = [];
        this.malwareCnt = 0;
        this.cryptoCnt = 0;
        this.allCrypto = 0;
        const levels = ["High", "Medium", "Low", "Informational", "Malware", "Well-Known"];
        // risk 별로 분류
        const dataArr = levels.map((level) =>
          Array.isArray(newVal)
            ? newVal.filter(
                (item) =>
                  item.risk && item.risk.toLowerCase() === level.toLowerCase()
              )
            : []
        );


        console.log("dataArr")
        console.log(dataArr)

        dataArr.forEach((group) => {
          if (group.length) {// 해당 risk 발견된 경우
            const uniqueAlerts = [];
            const aggregatedData = [];
            console.log("group")
            console.log(group);

            group.forEach((item) => {
              if(item.risk != 'Well-Known'){
                const idx = uniqueAlerts.indexOf(item.alert);
                if (idx === -1) {// 새로운 alert 값인 경우
                  uniqueAlerts.push(item.alert);
                  item.cnt = group.filter((i) => i.alert === item.alert).length;
                  item.instance = item.instance || [
                    {
                      url: item.url,
                      method: item.method || "N/A", 
                      param: item.param || "N/A",
                      description: item.description || "N/A",
                      solution: item.solution || "N/A",
                      reference: item.reference || "N/A",
                      other: item.other,
  
                    },
                  ];
                  aggregatedData.push(item);
                } else {
                    aggregatedData[idx].instance.push({
                    url: item.url || "N/A",
                    method: item.method || "N/A",
                    param: item.param || "N/A",
                    description: item.description || "N/A",
                    solution: item.solution || "N/A"
                  });
                  
                }
              }else{
                const idx = uniqueAlerts.indexOf(item.alert);
                if(idx==-1){
                  uniqueAlerts.push(item.alert)
                  item.instance=[{
                    url: item.url,
                    description:item.description,
                    solution: item.solution,
                    reference: item.reference,
                    name: item.other
                  }]
                  aggregatedData.push(item)
                }else{
                  aggregatedData[idx].instance.push({
                    url: item.url,
                    description:item.description,
                    solution: item.solution,
                    reference: item.reference,
                    name: item.other
                  })
                }
              }
            });

            console.log(group)

            
            group.forEach((x)=>{
              console.log(x);
              if(x.risk.toLowerCase() == 'malware'){
                if(x.instance){
                  this.allMalwares = x.instance.length;
                  const validInstances=[]; 
                  const instances =[];
                  x.instance.forEach((y)=>{
                    if(y.description !== 'no search malware at Url\n'){
                      validInstances.push(y);
                    }else{
                      instances.push(y);
                    }
                    
                  })
                  x.foundInst = validInstances; 
                  x.instance = instances;
                  this.malwareCnt = validInstances.length;
                }
              }
              if(x.risk == 'Well-Known'){
                const uniqueUrl = [];
                if (!x.validInst) x.validInst = {}; 
                if(x.instance){
                  x.instance.forEach((i)=>{
                    const idx = uniqueUrl.indexOf(i.url)
                     //인스턴스 수를 세기 위함
                    const instanceIdx = this.yaraInstance.indexOf(i.url);
                    if(instanceIdx == -1 ){
                      this.yaraInstance.push(i.url);
                    }
                    if(idx==-1){
                      uniqueUrl.push(i.url);
                      x.validInst[i.url]=[];
                      x.validInst[i.url].push(i);
                    }else{
                      x.validInst[i.url].push(i)
                    }
                  
                  })
                }
              }
            })

            console.log(aggregatedData)
            this.resultData.push(aggregatedData);
            console.log("this.resultData");
          }
        });

        const flattenedArray = this.resultData.flatMap(item => 
          Array.isArray(item) && item.length > 1 ? item.map(i => [i]) : [item]
        );
        
        this.resultData=flattenedArray
        console.log(this.resultData);
      }
    },
  },
};
</script>

<style>
.yaraTable tr td{
  padding: 5px;
}
.levelBox{
  height: 100%;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  padding: 0px 10px;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)!important;
}
.v-expansion-panel-content div::-webkit-scrollbar{
  display: none !important;
}
.content li{
  margin: 10px 0px !important;
  line-height: 1.2;
}
.content p{
  margin: 10px 0px !important;
}
.content pre{
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #EEEE;
}
.content code{
  white-space: pre-wrap;
}
pre code{
  background-color: transparent !important;
}

p{
  margin: 0px !important;
}
.ref-title{
  font-size: 0.8em;
  font-weight: bold;
  color: #555;
  margin: 10px 0px;
}
.risk{
  text-align: center;
  border-radius: 30px;
  padding: 5px 10px;
  color: white;
  width: fit-content;
}

.v-tooltip__content{
  margin-top: 10px !important;
}


.v-dialog__content,
.v-dialog::-webkit-scrollbar {
  scrollbar-width: none;
  background: transparent;
}

.scrollbar {
  scrollbar-width: none;
  background: transparent;
  width: 100vw !important;
  padding-left: 0px !important;
}

.tdBorder {
  border-radius: 10px;
}

.High {
  background-color: #FFEBEE;
  color: #B71C1C;
}
.Medium {
  background-color: #FFF3E0;
  color: #E65100;
}
.Low {
  background-color: #FFFDE7;
  color: #F57F17;
}
.Informational {
  background-color: #E3F2FD;
  color: #0D47A1;
}
.Malware {
  background-color: #EEEEEE;
  color: #212121;
}
.Well-Known {
  background-color: #EDE7F6;
  color: #311B92;
}
.popup {
  padding: 20px !important;
  padding-top: 45px !important;
}
.blueGrey{
  filter: invert(88%) sepia(3%) saturate(981%) hue-rotate(155deg) brightness(89%) contrast(81%);
}
.boxLayout{
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  font-size: 0.7rem;
}
#riskScore{
  filter: invert(13%) sepia(58%) saturate(4421%) hue-rotate(352deg) brightness(115%) contrast(90%);
}
</style>
