<template>
  <v-container fluid style="overflow: hidden; height: 100%; padding-bottom: 0px;">
    <v-data-table
      :headers="headers"
      :items="historyList"
      sort-by=""
      class="elevation-0"
      :search="search"
      @click:row="showItem"
      id="tableTest"
      style="height: 100% !important;"
    >
      <template v-slot:[`item.start_time`]="{ item }">
        {{ formatDateTime(item.start_time) }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="font-weight-bold"
            >Scan History
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div style="width: 450px">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </div>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top nudge-top="-10" tag="div">
          <template v-slot:activator="{ on, attrs }">
            <img
              src="@/assets/download.svg"
              class="btn"
              v-bind="attrs"
              v-on="on"
              @click="downloadItem(item, $event)"
            />
          </template>
          <div class="text-caption">Download</div>
        </v-tooltip>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-dialog v-model="dialog" v-if="selectedItem" :fullscreen="$vuetify.breakpoint.width<600?true:false" :width="$vuetify.breakpoint.width<600?'':'70%'">
        <scan-result-vue
          :vulnLevel1="vulnLevel"
          :scanurl="scanUrl"
          :result="alerts"
          :show-close-button="true"
          @close="dialog = false"
          :is-history-page="true"
          :timeTaken="timeTaken"
          :findings="findings"
          :startTime="startTime"
        ></scan-result-vue>
      </v-dialog>
    </div> 

    <div ref="test">
      <div v-for="(value, key) in downloadAlerts" :key="key" >
        <div class="riskBlock" v-if="value.risk == 'Malware'">
          <div v-if="value.validInst.length > 0 && value.validInst[0].description.startsWith('In Demo version,')">
            <div :class="['title pa-2',value.risk]" style="padding: 5pt; margin-bottom: 5pt;" :style="{borderLeft:`7pt solid ${getAlertColor(value.risk)}`}">
            {{ value.risk }}-Found in {{ value.validInst.length }} of {{ value.cnt }}
            </div>
            <div style="margin-bottom: 20pt;">
              <div class="subTitle">Instance Found ({{ value.validInst.length }})</div>
              <div class="content desc">{{ value.validInst[0].description }}</div>
            </div>
          </div>
          <div v-else>
            <div :class="['title pa-2',value.risk]" style="padding: 5pt; margin-bottom: 5pt;" :style="{borderLeft:`7pt solid ${getAlertColor(value.risk)}`}">
              {{ value.risk }}-Found in {{ value.validCnt }} of {{ value.cnt }}
            </div>
            <div style="margin-bottom: 20pt;">
              <div class="subTitle">Instance Found ({{ value.validCnt }})</div>
              <v-divider style="margin-bottom: 10px;"></v-divider>
              <div v-for="(item, idx) in value.validInst" :key="idx+'A'" class="content desc">
                <strong>URL:</strong> {{ item.url }} <br>
                <strong>Description:</strong> {{item.description }} <br>
                <strong>Solution:</strong> {{ item.solution }} <br>
              </div>
            </div>
            <div style="margin-bottom: 20pt;">
              <div class="subTitle">Not Found Instances</div>
              <v-divider style="margin-bottom: 10px;"></v-divider>
              <div
                class="content"
                v-for="(ins, l) in value.inst"
                :key="l"
                >
                  {{ ins.url }}
              </div>
            </div>

          </div>
        </div>

        <div class="riskBlock" v-else-if="value.risk == 'Well-Known'">
          <!-- <div v-if="value.validInst.length > 0 && cryptoDesc(value.validInst[0].description)=='text'">
            <div :class="['title pa-2',value.risk]" style="padding: 5pt; margin-bottom: 5pt;" :style="{borderLeft:`7pt solid ${getAlertColor(value.risk)}`}">
            {{ value.risk }}-Found in {{ value.validInst.length }} of {{ value.cnt }}
            </div>
            <div style="margin-bottom: 20pt;">
              <div class="subTitle">Instance Found ({{ value.validInst.length }})</div>
              <div class="content desc">{{ value.validInst[0].description }}</div>
            </div>
          </div> -->

          <div>
            <div :class="['title pa-2',value.risk]" style="padding: 5pt; margin-bottom: 5pt;" :style="{borderLeft:`7pt solid ${getAlertColor(value.risk)}`}">
              {{ value.name }}
            </div>
            <div style="margin-bottom: 20pt;">

              <div v-for="(item, key) in value.inst" :key="key" class="content">
                <div class="content"><strong>URL:</strong> {{ key }} <br></div>

                <div class="content desc">
                  <div
                  outlined
                  style="display: flex; flex-direction: column; border-radius: inherit; margin-top: 5pt; background-color: #f8f9fa; border-color: transparent; gap: 20pt;"
                  >
                    <v-simple-table
                    dense
                    v-for="(content, id) in item" :key="id"
                    class="yaraTable"
                    >
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{{ yaraName(content.name) }}</td>
                        </tr>
                        <tr>
                          <td>Description</td>
                          <td>{{ content.description }}</td>
                        </tr>
                        <tr>
                          <td>Reference</td>
                          <td>{{ content.reference }}</td>
                        </tr>
                        <tr>
                          <td>Solution</td>
                          <td>{{ content.solution }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </div>
                  <!-- {{ des.match_list[0] }} <strong> - {{ des.category }}</strong> <br><br>
                  <strong>Description:</strong> {{ des.desc }} <br><br>
                  <strong>Reference:</strong> {{ des.reference }} <br><br>
                  <strong>Solution:</strong> {{ des.solution }} <br><br> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="riskBlock" style="margin-bottom: 25pt;">
          <div :class="['title pa-2',value.risk]" style="padding: 5pt; margin-bottom: 5pt;" :style="{borderLeft:`7pt solid ${getAlertColor(value.risk)}`}">
            {{ value.risk }}-{{ value.name }}
          </div>
          <div style="margin-bottom: 20pt;">
            <div class="subTitle">Description</div>
            <v-divider style="margin-bottom: 10px;"></v-divider>
            <div  class="content desc">
              <div v-html="changeMarkdown(value.description)"></div>
              <div v-if="changeTags(value.tags).length>0">
                <div class="content">OWASP TOP 10</div>
                <ul>
                  <li v-for="(tag, idx) in changeTags(value.tags)" :key="idx"><a style="font-size: 0.8em;" :href="tag.url">{{tag.id}} (2021)-{{tag.title}}</a></li>
                </ul>
              </div>
              <div v-if="value.cweid.title != '0' && value.cweid != ''">
                <div class="content">Common Weakness Enumeration (CWE)</div>
                <ul>
                  <li><a style="font-size: 0.8em;"  :href="value.cweid.url">{{ value.cweid.title }}</a></li>
                </ul>
              </div>
              <div  v-if="value.wacsid.title != '0'  && value.wacsid != ''">
                <div class="content">Web Application Security Consortium (WASC)</div>
                <ul>
                  <li><a style="font-size: 0.8em;"  :href="value.wacsid.url">{{ value.wacsid.title }}</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div style="margin-bottom: 20pt;">
            <div class="subTitle">Found Instances ({{ value.instance.length }})</div>
            <v-divider style="margin-bottom: 10px;"></v-divider>
            <div class="content desc" style="border: 0.5pt solid darkgray; margin-top: 10pt;" v-for="(inst, g) in value.instance" :key="g">
                <span style="color: #28a745;">({{ inst.method }})</span><span style="color: #0d6efd; margin-left: 5pt;">{{ inst.url }}</span><br>
                <span>PARAMS: {{ inst.para || 'N/A' }}</span>
            </div>
          </div> 
          <div style="margin-bottom: 20pt;">
            <div class="subTitle">Solution</div>
            <v-divider style="margin-bottom: 10px;"></v-divider>
            <div class="content" v-html="changeMarkdown(value.solution)"></div>
          </div> 
          <div style=" margin-bottom: 20pt;">
            <div class="subTitle">Examples</div>
            <v-divider style="margin-bottom: 10px;"></v-divider>
            <div class="content" v-html="changeMarkdown(value.example)"></div>
          </div>
          <div style="margin-bottom: 20pt;">
            <div class="subTitle">Other Information</div>
            <v-divider style="margin-bottom: 10px;"></v-divider>
            <div v-html="changeMarkdown(value.other)" class="content"></div>
          </div>
          <div style="margin-bottom: 20pt;">
            <div class="subTitle">References</div>
            <v-divider style="margin-bottom: 10px;"></v-divider>
            <div v-html="changeMarkdown(value.reference)" class="content"></div>
          </div>
        </div>

      </div>
    </div>

  </v-container>
</template>

<script>
import scanResultVue from "./scanResult.vue";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { marked } from "marked";
import hljs from "highlight.js";
import { markedHighlight } from "marked-highlight";
import "highlight.js/styles/github.css";
import { PDFDocument, rgb, StandardFonts} from "pdf-lib";
import { nextTick } from "vue";
import {owasp} from "@/assets/owasp.json"


// import { address } from "@/mixins/api";

export default {
  name: "ScanHistory",
  components: {
    scanResultVue,
  },
  data: () => ({
    owaspArr:owasp,
    yaraInstance:[],
    alerts: [],
    downloadAlerts: [],
    scanResult: true,
    scanUrl: null,
    search: "",
    timeTaken: null,
    findings: 0,
    totalVulns: 0,
    dialog: false,
    headers: [
      { text: "No", value: "no", align: "center" },
      { text: "URL", value: "scan_url", sortable: false, align: "center" },
      { text: "Start Time", value: "start_time", align: "center" },
      { text: "Actions", value: "actions", sortable: false, align: "center" },
    ],
    historyList: [],
    vulnLevel: [
      { level: "High", color: "error", value: 0 },
      { level: "Medium", color: "orange accent-2", value: 0 },
      { level: "Low", color: "yellow lighten-2", value: 0 },
      { level: "Info", color: "blue accent-1", value: 0 },
      { level: "Malware", color: "grey lighten-1", value: 0 },
      { level: "Well-Known", color: "brown lighten-1", value: 0 },
    ],
    selectedItem: null,
  }),
  created() {
    this.fetchHistory();
    // document.addEventListener("DOMContentLoaded", function () {
    //   // 모든 코드 블록에 대해 하이라이팅 적용
    //   hljs.highlightAll();
    // });
  },
  mounted(){
    marked.use(markedHighlight({
        renderer: new marked.Renderer(),
        gfm: true,
        headerIds: false,
        tables: true,
        breaks: true,
        langPrefix: 'hljs language-',
        highlight:function(code, lang){
          const validLanguage = lang && hljs.getLanguage(lang) ? lang : 'plaintext';
          return hljs.highlight(code, { language: validLanguage }).value;
        },
        pedantic: false,
        sanitize: false,
        smartLists: true,
        smartypants: false
      }));

    //   this.$nextTick(() => {
    //   document.querySelectorAll("pre code").forEach((el) => {
    //     hljs.highlightElement(el);
    //     el.style.background = "#EEEEEE";
    //   });
    // });
  },

  methods: {
    yaraName:function(text){
      console.log(text);
      const arr = text.replace(/\[|\]/g, '').split(',').filter(item => item);   
      console.log(arr);
      return arr[0]
    },
    changeTags:function(tags){
      console.log(tags);
      const jsonObj = JSON.parse(tags);

      const filtered = Object.fromEntries(
        Object.entries(jsonObj).filter((i)=> i[0].startsWith("OWASP_2021"))
      );
      const arr= [];
      console.log(filtered);
      Object.keys(filtered).forEach(key => {
        const modifiedKey = key.substring(key.lastIndexOf("_")+1);
        const matchedItem = this.owaspArr.find(item => item.id == modifiedKey);

        if(matchedItem){
          arr.push({
            ...matchedItem,
            url: filtered[key]
          });
        }
      })
      console.log(arr)
      return arr;
    },
    checkField: function(field){
      const fieldArr = ['description', 'solution', 'other', 'examples','reference'];
      if(fieldArr.includes(field)){
        return true;
      }else{
        return false;
      }
    },
    changeMarkdown:function(text){
      const html = marked(text);
      return html;
    },
    //서버에 실제 데이터 요청
    async fetchHistory() {
      try {
        const response = await axios.post(
          `${this.$apiUrl}/webscan/history`,
          {},
          { withCredentials: true }
        );
        this.historyList = response.data
          .map((item, index) => ({
            ...item,
            no: index + 1,
          }))
          .sort((a, b) => new Date(b.start_time) - new Date(a.start_time));
      } catch (error) {
        console.error("Error fetching scan history:", error);
      }
    },
    async scanAlert(scanId) {
      try {
        const response = await axios.post(
          `${this.$apiUrl}/webscan/scan/alerts`,
          { scan_id: scanId },
          { withCredentials: true }
        );
        console.log("API Response for scanAlert:", response.data);

        this.alerts = response.data;
        // if (!Array.isArray(this.alerts)) {
        //   throw new Error("Expected alerts to be an array");
        // }

        let highCnt = 0,
          medCnt = 0,
          lowCnt = 0,
          infoCnt = 0,
          malCnt = 0;

        this.alerts.forEach((alert) => {
          switch (alert.risk) {
            case "High":
              highCnt++;
              break;
            case "Medium":
              medCnt++;
              break;
            case "Low":
              lowCnt++;
              break;
            case "Information":
              infoCnt++;
              break;
            case "Malware":
              malCnt++;
              break;
            case "Well-Known":
              malCnt++;
              break;
          }
          this.findings = highCnt + medCnt + lowCnt + infoCnt + malCnt;
        });
        this.findings = highCnt + medCnt + lowCnt + infoCnt + malCnt;
        this.vulnLevel = this.vulnLevel.map((level) => {
          switch (level.level) {
            case "High":
              return { ...level, value: highCnt };
            case "Medium":
              return { ...level, value: medCnt };
            case "Low":
              return { ...level, value: lowCnt };
            case "Information":
              return { ...level, value: infoCnt };
            case "Malware":
              return { ...level, value: malCnt };
            case "Well-Known":
              return { ...level, value: malCnt };
            default:
              return level;
          }
        });

        this.scanResult = true;
        console.log("Scan results fetched successfully");
      } catch (error) {
        // console.error("Error fetching scan alerts:", error);
        // this.vulnLevel.forEach((level) => (level.value = 0));
        // this.alerts=[];

      }
    },
    //어떤 'history' 요소를 클릭하면 'scanResult' 컴포넌트가 표시된다.
    showItem(item) {
      console.log(item);
      this.selectedItem = item;
      this.scanUrl = item.scan_url;
      this.alerts = [];
      this.dialog = true;
      this.findings = 0;
      this.startTime = this.formatDateTime(item.start_time)
      if (item.start_time && item.end_time && item.end_time > item.start_time) {
        this.timeTaken = this.calculateDuration(
          new Date(item.start_time),
          new Date(item.end_time)
        );
      } else {
        this.timeTaken = "0";
      }
      this.scanAlert(item.id);
    },

    getAlertColor(risk) {
      switch (risk) {
        case "High":
          return "#B71C1C";
        case "Medium":
          return "#E65100";
        case "Low":
          return "#F57F17";
        case "Informational":
          return "#0D47A1";
        case "Information":
          return "#0D47A1";
        case "Malware":
          return "#212121";
        case "Well-Known":
          return "#311B92";
        case "Crypto":
          return "#311B92";
        default:
          return "#FFFFFF";
      }
    },
    getBackgroundColor(risk) {
      switch (risk) {
        case "High":
          return "#FFEBEE";
        case "Medium":
          return "#FFF3E0";
        case "Low":
          return "#FFFDE7";
        case "Informational":
          return "#E3F2FD";
        case "Information":
          return "#E3F2FD";
        case "Malware":
          return "#EEEEEE";
        case "Well-Known":
          return "#EDE7F6";
        case "Crypto":
          return "#EDE7F6";
        default:
          return "#FFFFFF";
      }
    },

    //pdf 파이일 만들때 위로 부분에 사이트 이름/스캔 날짜 추가
    addHeader(doc, itemId, formattedStartTime) {
      const headerText = `Wikisecurity Website Scanner\nTest ID: ${itemId}\nDate and time: ${formattedStartTime}`;
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.text(headerText, 14, 15);
      doc.setDrawColor(0, 0, 0);
      doc.line(14, 26, doc.internal.pageSize.width - 14, 26);
    },
    //날짜 및 시간 필요한 형식으로 표시
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      const options = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      };
      return date.toLocaleDateString("en-US", options);
    },
    //스캔 걸린 시간 계산
    calculateDuration(startTime, endTime) {
      const timeDiff = new Date(endTime - startTime);
      const hours = String(timeDiff.getUTCHours()).padStart(2, "0");
      const minutes = String(timeDiff.getUTCMinutes()).padStart(2, "0");
      const seconds = String(timeDiff.getUTCSeconds()).padStart(2, "0");
      this.timeTaken = `${hours}:${minutes}:${seconds}`;
      return this.timeTaken;
    },

    cryptoDesc: function(desc) {
      try {
          const text = 'In Demo version, If Target site has over 100 resource then Well-Known scan not Execute';
          console.log("desc>>>>>>>>>>>>>", desc);
          if(desc != text){
            let desc1 = desc.replace(/'/g, '"');
            desc1 = desc1.replace(/(\{|\s)([A-Za-z_][A-Za-z0-9_]*)(:)/g, '$1"$2"$3');
            desc1 = desc1.replace(/(\[|\s)([A-Z_][A-Za-z0-9_]*)(\]|,)/g, '$1"$2"$3');
            desc1 = desc1.replace(/\n/g, '');
            var cnt = 0;
            const descArr = JSON.parse(desc1);
            const finalArr = descArr.reduce((acc, item)=>{
              if(!acc[item.category]){
                acc[item.category] = [];
              }
              acc[item.category].push(item);
              cnt++;
              return acc;
            },{});
  
            return Array.isArray(descArr) ? {data:finalArr, cnt: cnt} : [];

          }else{
            return 'text';
          }
      } catch (error) {
        console.error('Error parsing description:', error);
        return [];
      }
    },

    cweid: function(cwe){
      if(cwe){
        console.log("cwe")
        console.log(cwe)
        const arr = cwe.split("<br>");
        return { title: arr[0], url: arr[1]}
      }else{
        return null;
      }
    },

    async downloadItem(item, event) {
      event.stopPropagation();
      try {
        const response = await axios.post(
          `${this.$apiUrl}/webscan/scan/alerts`,
          { scan_id: item.id },
          { withCredentials: true }
        );
        console.log(response)
        const combinedPdf = await PDFDocument.create();   
        const alerts = response.data;
        const formattedStartTime = this.formatDateTime(item.start_time);
        const finishTime = this.formatDateTime(item.end_time);
        const duration = this.calculateDuration(
          new Date(item.start_time),
          new Date(item.end_time)
        );
        const riskOrder = ["High", "Medium", "Low", "Information", "Malware", "Well-Known"];
        if(Array.isArray(alerts)){
          const doc = new jsPDF();
          const groupedAlerts = {};
          alerts.forEach((alert) => {
            const key = `${alert.risk}-${alert.alert}`;
            if (!groupedAlerts[key]) {
              groupedAlerts[key] = {
                ...alert,
                count: 1,
                instances: [
                  {
                     url: alert.url, 
                    method: alert.method, 
                    param: alert.param, 
                    description: alert.description, 
                    solution: alert.solution, 
                    reference: alert.reference, 
                    name: alert.other 
                  },
                ],
              };
            } else {
              groupedAlerts[key].count += 1;
              groupedAlerts[key].instances.push({
                url: alert.url,
                method: alert.method,
                param: alert.param,
                description: alert.description,
                solution: alert.solution,
                name: alert.other,
                reference: alert.reference
              });
            }
          });
  
          
          console.log("groupedAlerts");
          console.log(groupedAlerts);
          this.downloadAlerts=[];
          for(const key in groupedAlerts){
            const value = groupedAlerts[key];
            if(typeof value === "object"){
              let name1 = key? key.replace(/<br>/g, ' ') : '';
              let risk = name1.substring(0, name1.indexOf("-")) != 'Well' ? name1.substring(0, name1.indexOf("-")): name1.substring(0, name1.lastIndexOf("-"))
              let name = risk != 'Well-Known'? name1.substring(name1.indexOf("-")+1):name1.substring(name1.lastIndexOf("-")+1)
              if(risk != 'Malware'&& risk != 'Well-Known'){
                console.log('information');
                console.log(risk);
                let desc = value['description'];
                let oth = value['other'];
                let sol = value['solution'];
                let ref = value['reference'];
                let ex = value['examples'];
                let tags = value['tags'];
                let instance = value['instances']
                let cweid = this.cweid(value['cweid'])
                console.log(cweid);
                let wacsid = this.cweid(value['wascid'])
                this.downloadAlerts.push(
                  {
                    risk: risk || '',
                    name: name || '',
                    description: desc || '',
                    solution: sol || '',
                    other: oth || '',
                    reference: ref || '',
                    example: ex || '',
                    cweid: cweid || '',
                    wacsid: wacsid || '',
                    tags: tags || '',
                    instance: instance || ''
                  }
                )
              }else if(risk == 'Malware'){
                console.log(">>>>>>>>>>>>malware")
                console.log(value);
                let instances = value['instances'];
                let cnt = value['count'];
                console.log(instances);
                const validInstances=instances.filter(item => item.description 
                && item.description !== 'no search malware at Url\n' && item.description !== 'Connection failed please check url\n');
                instances = instances.filter(item => item.description 
                && item.description == 'no search malware at Url\n' || item.description == 'Connection failed please check url\n');
                this.downloadAlerts.push(
                  {
                    risk: risk || '',
                    validInst: validInstances,
                    validCnt: validInstances.length,
                    cnt: cnt,
                    inst: instances
                  }
                )
              }else if(risk == 'Well-Known'){
                const uniqueUrl = [];
                if(value['instances']){
                  let instances = value['instances'];
                  instances.forEach((i)=>{
                    const idx = uniqueUrl.indexOf(i.url);
                    const idx1 = this.yaraInstance.indexOf(i.url);
                    if(idx1 == -1){
                      this.yaraInstance.push(i.url);
                    }
                    if(idx == -1){
                      uniqueUrl.push(i.url);
                      value['validInst']={};
                      value['validInst'][i.url]=[];
                      value['validInst'][i.url].push(i);
                    }else{
                      value['validInst'][i.url].push(i);
                    }
                  })
                }
                
                this.downloadAlerts.push(
                  {
                    risk: risk || '',
                    inst: value['validInst'] || '',
                    name: value['alert'] || ''
                  }
                )
              }
            }
          }
          console.log("this.downloadAlerts");
          console.log(this.downloadAlerts);
  
          const riskOrder1 = ["High", "Medium", "Low", "Informational", "Malware", "Well-Known"];
          this.downloadAlerts.sort((a, b) => {
            return riskOrder1.indexOf(a.risk) - riskOrder1.indexOf(b.risk);
          });
  
          let totalPages;
          let currentPage = 1;
          // let detailedReportAdded = false;
          const addHeaderIfNeeded = (pageNumber) => {
            if (pageNumber <= 2 || (totalPages && pageNumber === totalPages)) {
              // this.addHeader(doc, item.id, formattedStartTime);
            }
          };
          addHeaderIfNeeded(currentPage);
          const tableRows = [];
          this.totalVulns = 0;
          console.log(groupedAlerts)
          console.log("groupedAlerts")
          Object.values(groupedAlerts).forEach((alert) => {
            const riskColor = this.getAlertColor(alert.risk);
            const backColor = this.getBackgroundColor(alert.risk);
            const cleanAlertName = alert.alert ? alert.alert.replace(/<br>/g, ' ') : '';
            //Malware 경우에 데이터를 받기 위해, 'description'을 기준으로 필터링하고, description 비어 있지 않고, null이 아니며 실제 데이터일 경우에만 저장한다
            //Yara 경우 똑같은 방법
            if (alert.risk === "Malware") {
              const validInstanceCount = alert.instances.filter(instance => instance.description 
              && instance.description !== 'no search malware at Url\n' && instance.description !== 'Connection failed please check url\n').length;
              // const customMessage = `Malware was found in ${validInstanceCount} of ${alert.count} resources`;
              this.totalVulns += validInstanceCount;
              tableRows.push({
                contents: { content: alert.risk, styles: { fillColor: backColor, textColor: riskColor } },
                cnt: alert.count,
                validCnt: validInstanceCount,
              });
            } else if(alert.risk === "Informational"){
              this.totalVulns += alert.count;
              tableRows.push({
                contents: { content: alert.risk = 'Information', styles: { fillColor: backColor, textColor: riskColor } },
                name: cleanAlertName,
                cnt: alert.count
              });
            } else if(alert.risk === "Well-Known"){
              tableRows.push({
                contents: { content: alert.risk = 'Well-Known', styles: { fillColor: backColor, textColor: riskColor } },
                name: alert.alert,
                cnt:  Object.keys(alert.validInst).length,
              });
            }
            else {
              this.totalVulns += alert.count;
              tableRows.push({
                contents: { content: alert.risk, styles: { fillColor: backColor, textColor: riskColor, halign: "center" } },
                name: cleanAlertName,
                cnt:alert.count,
              });
            }
          });
          this.totalVulns+=this.yaraInstance.length;
          console.log(this.totalVulns)

  
          tableRows.sort((a, b) => {
            return riskOrder.indexOf(a.contents.content) - riskOrder.indexOf(b.contents.content);
          });
          const vulnRows = riskOrder.map(risk => ({content: risk, cnt: 0}))
          tableRows.forEach((item)=>{
            const index = vulnRows.findIndex(v => v.content == item.contents.content);
            if(index !== -1){
              vulnRows[index].cnt = item.contents.content=="Malware"?item.validCnt:item.contents.content=="Well-Known"?this.yaraInstance.length:item.cnt
            }
          })
  
  
          //pdf 파일 Detail Report 부분에 상세 정보 만들기
          doc.setFontSize(18);
          doc.setFont("helvetica", "bold");
          doc.text("Wiki Web Scanner Report", 14, 45);
          doc.setFontSize(16);
          doc.setFont("helvetica", "bold");
          doc.text("Summary", 14, 60);
          const leftX = 14;
          const rightX = 90;
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          const lineSpacing = 7;
          doc.text("Target Site:", leftX, 70);
          doc.setFont("helvetica", "normal");
          doc.text(`${item.scan_url}`, rightX, 70);
          doc.setFont("helvetica", "bold");
          doc.text("Start Date/Time:", leftX, 70 + lineSpacing);
          doc.setFont("helvetica", "normal");
          doc.text(`${formattedStartTime}`, rightX, 70 + lineSpacing);
          doc.setFont("helvetica", "bold");
          doc.text("Finish Date/Time:", leftX, 70 + 2 * lineSpacing);
          doc.setFont("helvetica", "normal");
          doc.text(`${finishTime}`, rightX, 70 + 2 * lineSpacing);
          doc.setFont("helvetica", "bold");
          doc.text("Duration:", leftX, 70 + 3 * lineSpacing);
          doc.setFont("helvetica", "normal");
          doc.text(`${duration}`, rightX, 70 + 3 * lineSpacing);
          doc.setFont("helvetica", "bold");
          doc.text("Report Generated on:", leftX, 70 + 4 * lineSpacing);
          doc.setFont("helvetica", "normal");
          doc.text(`${finishTime}`, rightX, 70 + 4 * lineSpacing);
          
          doc.setFontSize(20);
          doc.setFont("helvetica", "bold");
          doc.text(`Risk Score:  ${this.totalVulns}`, 14, 115);
  
          
          console.log("tableRows")
          console.log(tableRows)
  
          // risk Score ~ 각 risk별 cnt 표시
          const head = [
            vulnRows.map(item => ({
              content: item.cnt,
              styles:{
                fillColor: this.getBackgroundColor(item.content),
                textColor: this.getAlertColor(item.content),
                fontSize: 25,
                halign: "center"
              }
            }))
          ];
  
          const body = [
            vulnRows.map(item => ({
              content: item.content,
              styles: {
                fillColor: this.getBackgroundColor(item.content),
                textColor: this.getAlertColor(item.content),
                fontSize: 13,
                halign: "center"
              }
            }))
          ]
          const columnWidth = (doc.internal.pageSize.width-28)/6;
          doc.autoTable({
            startY: 120,
            head: head,
            body: body,
            styles: { fontSize: 12, cellPadding: 3, halign: "center", lineWidth: 0 },
            columnStyles: {
              0: { cellWidth: columnWidth},
              1: { cellWidth: columnWidth},
              2: { cellWidth: columnWidth},
              3: { cellWidth: columnWidth},
              4: { cellWidth: columnWidth}
            },
            didDrawCell: (data)=>{
              const {cell} = data;
  
              if(cell){
                doc.setDrawColor(255, 255, 255); // 흰색 선
                doc.setLineWidth(2); // 굵기 조절
                doc.line(cell.x + cell.width, cell.y, cell.x + cell.width, cell.y + cell.height); // 오른쪽 세로선
                doc.line(cell.x, cell.y, cell.x, cell.y + cell.height); // 왼쪽 세로선
              }
            }
          });
  
          // vulnerability list
          const body1 = tableRows.map(row => {
            return [
              {
                content: row.contents.content,
                styles: {
                  fillColor: row.contents.styles.fillColor,
                  textColor: row.contents.styles.textColor,
                  halign: 'center',
                }
              },
              row.name || `${row.contents.content} was found in ${row.validCnt} of ${row.cnt}`,
              {
                content: row.contents.content=="Malware"?row.validCnt:row.cnt,
                styles: {
                  halign: 'center'
                }
                
              }
            ]
          })
          doc.autoTable({
            startY: 155,
            startX: 16,
            theme: 'striped',
            head: [
              [
                {
                  content: "Risk Level",
                  styles: {
                    halign: 'center'
                  }
                },
                {
                  content: "Vulnerability Name"
                },
                {
                  content: "Instances",
                  styles: {
                    halign: 'center'
                  }
                }
              ]],
            body: body1,
            styles: {
              lineWidth: 0,
              cellPadding: 2,
              fontSize: 10,
              valign: "middle",
              halign: "left",
            },
            headStyles: {
              textColor: [0, 0, 0],
              fillColor: "#BDBDBD",
              fontStyle: 'normal'
            },
            didDrawCell: function(data){
              const {doc, cell, column, row} = data;
              const table = data.table;
  
              if (column.index === 0) {
                doc.setDrawColor("#BDBDBD"); 
                doc.setLineWidth(0.3); // 굵기 설정
                doc.line(cell.x, cell.y, cell.x, cell.y + cell.height); // 세로선
              }
  
              if (column.index === table.columns.length - 1) {
                doc.setDrawColor("#BDBDBD"); 
                doc.setLineWidth(0.3); // 굵기 설정
                doc.line(cell.x + cell.width, cell.y, cell.x + cell.width, cell.y + cell.height); // 세로선
              }
  
              // ✅ 맨 아래(border-bottom) 스타일 적용
              if (row.index === table.body.length - 1) {
                doc.setDrawColor("#BDBDBD"); 
                doc.setLineWidth(0.3); // 굵기 설정
                doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height); // 가로선
              }
            }
          });
               
  
          const firstPdfBytes = doc.output('arraybuffer');
          const firstPdfDoc = await PDFDocument.load(firstPdfBytes);
          const firstPages = await combinedPdf.copyPages(firstPdfDoc, firstPdfDoc.getPages().map((_, index) => index));
          firstPages.forEach(page => combinedPdf.addPage(page));
  
  
          await nextTick();
          
          const test1 = this.$refs.test;
          test1.style.display = "block"
  
          const riskBlocks = document.querySelectorAll('.riskBlock');
          console.log(riskBlocks);
          
  
          for(let i = 0 ; i<riskBlocks.length; i++){
            const doc1 = new jsPDF();
            // this.addHeader(doc1, item.id, formattedStartTime);
  
            await doc1.html(riskBlocks[i],{
              x: 14,
              y: 0,
              scale: 1.5,
              margin: [25, 0, 25, 0],
              width: doc.internal.pageSize.width-28,
              windowWidth: riskBlocks[i].scrollWidth, 
              autoPaging:true
            });
  
            const pdfBytes = doc1.output('arraybuffer'); // jsPDF PDF를 ArrayBuffer로 변환
            const pdfDoc = await PDFDocument.load(pdfBytes); // pdf-lib로 로드
            
            // 해당 페이지를 combinedPdf에 병합
            const copiedPages = await combinedPdf.copyPages(pdfDoc, pdfDoc.getPages().map((_, index) => index));
            copiedPages.forEach(page => {
              combinedPdf.addPage(page);
            });
          }
          test1.style.display = "none"
  
          const helveticaFont = await combinedPdf.embedFont(StandardFonts.Helvetica);
          const pages = combinedPdf.getPages();
  
          pages.forEach((page, index)=>{
            const {width, height} = page.getSize();
            const pageNumber = `${index+1}/${pages.length}`;
  
            page.drawText('Wikisecurity Website Scanner',{
              x:37,
              y:height-23,
              size:10,
              font:helveticaFont,
              color: rgb(0,0,0)
            })
  
            page.drawText(`Test ID: ${item.id}`,{
              x:37,
              y:height-35,
              size:10,
              font:helveticaFont,
              color: rgb(0,0,0)
            })
  
            page.drawText(`Date and time: ${formattedStartTime}`,{
              x:37,
              y:height-47,
              size:10,
              font:helveticaFont,
              color: rgb(0,0,0)
            })
  
            page.drawLine({
              start: {x:37, y:height-54,}, 
              end: {x:width-37, y:height-54},
              thickness: 1,
              color: rgb(0,0,0),
            })
  
            page.drawText(pageNumber,{
              x: width/2,
              y: 20,
              size: 10,
              font: helveticaFont,
              color: rgb(0,0,0)
            })
  
          })
  
          const combinedPdfBytes = await combinedPdf.save();
          const blob = new Blob([combinedPdfBytes], { type: 'application/pdf' });
          const link = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          link.href = objectUrl;
          link.download = `scan_report_${item.id}.pdf`;
          link.click();
          URL.revokeObjectURL(objectUrl);
        }else{
          console.log(item);
          const doc = new jsPDF();

          this.addHeader(doc, item.id, formattedStartTime);


          doc.setFontSize(18);
          doc.setFont("helvetica", "bold");
          doc.text("Wiki Web Scanner Report", 14, 45);
          doc.setFontSize(16);
          doc.setFont("helvetica", "bold");
          doc.text("Summary", 14, 60);
          const leftX = 14;
          const rightX = 90;
          doc.setFont("helvetica", "bold");
          doc.setFontSize(12);
          const lineSpacing = 7;
          doc.text("Target Site:", leftX, 70);
          doc.setFont("helvetica", "normal");
          doc.text(`${item.scan_url}`, rightX, 70);
          doc.setFont("helvetica", "bold");
          doc.text("Start Date/Time:", leftX, 70 + lineSpacing);
          doc.setFont("helvetica", "normal");
          doc.text(`${formattedStartTime}`, rightX, 70 + lineSpacing);
          doc.setFont("helvetica", "bold");
          doc.text("Finish Date/Time:", leftX, 70 + 2 * lineSpacing);
          doc.setFont("helvetica", "normal");
          doc.text(`${finishTime}`, rightX, 70 + 2 * lineSpacing);
          doc.setFont("helvetica", "bold");
          doc.text("Duration:", leftX, 70 + 3 * lineSpacing);
          doc.setFont("helvetica", "normal");
          doc.text(`${duration}`, rightX, 70 + 3 * lineSpacing);
          doc.setFont("helvetica", "bold");
          doc.text("Report Generated on:", leftX, 70 + 4 * lineSpacing);
          doc.setFont("helvetica", "normal");
          doc.text(`${finishTime}`, rightX, 70 + 4 * lineSpacing);
          
          doc.setFontSize(20);
          doc.setFont("helvetica", "bold");
          doc.text(`Risk Score:  ${this.totalVulns}`, 14, 115);
  
  
          // risk Score ~ 각 risk별 cnt 표시
          const head = [
            riskOrder.map(item => ({
              content: 0,
              styles:{
                fillColor: this.getBackgroundColor(item),
                textColor: this.getAlertColor(item),
                fontSize: 25,
                halign: "center"
              }
            }))
          ];
  
          const body = [
          riskOrder.map(item => ({
              content: item,
              styles: {
                fillColor: this.getBackgroundColor(item),
                textColor: this.getAlertColor(item),
                fontSize: 13,
                halign: "center"
              }
            }))
          ]
          const columnWidth = (doc.internal.pageSize.width-28)/6;
          doc.autoTable({
            startY: 120,
            head: head,
            body: body,
            styles: { fontSize: 12, cellPadding: 3, halign: "center", lineWidth: 0 },
            columnStyles: {
              0: { cellWidth: columnWidth},
              1: { cellWidth: columnWidth},
              2: { cellWidth: columnWidth},
              3: { cellWidth: columnWidth},
              4: { cellWidth: columnWidth}
            },
            didDrawCell: (data)=>{
              const {cell} = data;
  
              if(cell){
                doc.setDrawColor(255, 255, 255); // 흰색 선
                doc.setLineWidth(2); // 굵기 조절
                doc.line(cell.x + cell.width, cell.y, cell.x + cell.width, cell.y + cell.height); // 오른쪽 세로선
                doc.line(cell.x, cell.y, cell.x, cell.y + cell.height); // 왼쪽 세로선
              }
            }

          });

          doc.setFontSize(10);
          doc.text(
            '1/1',
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.height - 10,
            { align: "center" }
          );

          doc.save(`scan_report_${item.id}.pdf`)
        }

      } catch (error) {
        console.error("Error downloading scan report:", error);
      }
    },
  }
};
</script>

<style scoped>
a {
  pointer-events: none;         /* URL 하이퍼링크 색상 변경 */
}
.desc{
  padding: 5pt;
  border-radius: 4pt;
  background-color: rgb(248, 249, 250);
}
.content{
  font-size: 17pt;
  padding: 5pt;
  letter-spacing: .0125em;
  font-family: Arial, Helvetica, sans-serif;
  display: block;
}

::v-deep(.yaraTable tbody tr td){
  font-size: 17pt !important;
  padding: 5pt !important;
}

::v-deep(.yaraTable tbody tr td:first-child){
  font-weight: bold;
}

::v-deep(.yaraTable tbody tr,.yaraTable tbody ){
  border-collapse: collapse;
}

::v-deep(.yaraTable tbody ){
  border-top: 0.7pt solid black;
  border-bottom: 0.7pt solid black;
}

::v-deep( .content ul){
  padding-left:15pt !important;
}

::v-deep( .content ul li){
  text-indent:5pt !important;
}

::v-deep( .content ol){
  padding-left:15pt !important;
}

::v-deep( .content ol li){
  text-indent:5pt !important;
}

.subTitle{
  font-size: 20pt;
  font-weight: bold;
  letter-spacing: .0125em;
  font-family: Arial, Helvetica, sans-serif;
}
.title{
  font-size: 25pt;
  font-weight: bold;
  letter-spacing: .0125em;
  font-family: Arial, Helvetica, sans-serif;
}
.btn {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  filter: invert(66%) sepia(8%) saturate(576%) hue-rotate(155deg)
    brightness(95%) contrast(94%);
}
.v-tooltip--top {
  width: fit-content;
  padding: 0px !important;
}
.v-toolbar__title{
  width: 100% !important;
}
.High {
  background-color: #FFEBEE;
  color: #B71C1C;
}
.Medium {
  background-color: #FFF3E0;
  color: #E65100;
}
.Low {
  background-color: #FFFDE7;
  color: #F57F17;
}
.Informational {
  background-color: #E3F2FD;
  color: #0D47A1;
}
.Malware {
  background-color: #EEEEEE;
  color: #212121;
}
.Well-Known {
  background-color: #EDE7F6;
  color: #311B92;
}

</style>
