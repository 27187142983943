<template>
  <v-app>
    <v-navigation-drawer
      app
      clipped
      absolute
      hide-overlay
      mobile-breakpoint=""
      v-if="isMobile"
      style="min-height: 555px;"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <iframe style="min-width: 220px;" width="100%" height="210" src="https://www.youtube.com/embed/XrpjJBbNigc?si=qWuJtNWHyJvX7Bv2&mute=1" frameborder="0" allow="encrypted-media" allowfullscreen></iframe>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="item in filteredItems"
          :key="item.title"
          :to="item.href"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isLogged" @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>LOGOUT</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div style="position: absolute; bottom: 0px; width: 100%;">
        <v-divider></v-divider>
        <v-card-title 
          style="min-height: 30px !important; padding: 0px !important;justify-content: center !important; margin-top: 10px;"
        >
          <a 
            v-for="(icon,index) in icons"
            :href="icon.link"
            v-bind:key="index"
            target="_blank"
            style="width: 50px; display: flex; text-decoration: none; justify-content: center; align-items: center;"
            class="snsBtn"
          >
            <v-hover v-slot="{hover}">
              <v-btn
                class="mx-4"
                icon
                :color="hover ? icon.color : 'rgba(0,0,0,.54)'"
              >
                <v-icon size="24px">
                  {{ icon.icon }}
                </v-icon>
              </v-btn>
            </v-hover>
          </a>
        </v-card-title>
        <div class="pa-0 text-center">info@wikisecurity.net</div>
        <div class="pa-1 text-center text-caption">Don't Miss To Follow Us On Our Social Networks Official Accounts.</div>
      </div>
    </v-navigation-drawer>
    <v-navigation-drawer      
      v-if="!isMobile && drawer"
      v-model="drawer"
      app
      clipped
      temporary
      hide-overlay
      width=""
      mobile-breakpoint=""
      style="min-height: 555px; width: 256px; top: 48px; z-index: 20;"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <iframe style="min-width: 220px;" width="100%" height="210" src="https://www.youtube.com/embed/XrpjJBbNigc?si=qWuJtNWHyJvX7Bv2&amp;mute=1" frameborder="0" allow="encrypted-media" allowfullscreen></iframe>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="item in filteredItems"
          :key="item.title"
          :to="item.href"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="isLogged" @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>LOGOUT</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div style="position: absolute; bottom: 0px; width: 100%;">
        <v-divider></v-divider>
        <v-card-title 
          style="min-height: 30px !important; padding: 0px !important;justify-content: center !important; margin-top: 10px;"
        >
          <a 
            v-for="(icon,index) in icons"
            :href="icon.link"
            v-bind:key="index"
            target="_blank"
            style="width: 50px; display: flex; text-decoration: none; justify-content: center; align-items: center;"
            class="snsBtn"
          >
            <v-hover v-slot="{hover}">
              <v-btn
                class="mx-4"
                icon
                :color="hover ? icon.color : 'rgba(0,0,0,.54)'"
              >
                <v-icon size="24px">
                  {{ icon.icon }}
                </v-icon>
              </v-btn>
            </v-hover>
          </a>
        </v-card-title>
        <div class="pa-0 text-center">info@wikisecurity.net</div>
        <div class="pa-1 text-center text-caption">Don't Miss To Follow Us On Our Social Networks Official Accounts.</div>
      </div>
    </v-navigation-drawer>
    
    <v-app-bar
      app
      color="blue-grey lighten-4"
      dense
      absolute
      elevation="3" 
      light
      id="main-bar"
      style="z-index: 200;"
    >
    <v-tab
    class="home-btn"
    :to="'/'"
    type="button"
    >
      <v-img
          class="mb-1;"
          max-width="69"
          contain
          src="./assets/logo.png"
          style="cursor: pointer;"
        >
      </v-img>
    </v-tab>
      <div class="px-3" style="font-size: 20px; margin-top: 5px; font-weight: bold;" v-if="$vuetify.breakpoint.width>600">Web Vulnerability Scanner</div>
      <div class="px-3" style="font-size: 20px;" v-if="$vuetify.breakpoint.width<=600">WS</div>
      <div v-if="isLogged" style="height: 100%; position: absolute; right: 10px; display: flex; align-items: center; justify-content: center;">
        <div style="text-align: right; margin-right: 5px;">
          <div style="font-size: 18px; font-weight: bold; height: 20px;">
            {{ userData.username }}
          </div>
        </div>
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div style="width: 30px; height: 30px;">
                <img src="@/assets/account.svg" v-bind="attrs" v-on="on" />
              </div>
            </template>

            <v-list id="userMenu" class="pa-1">
              <v-card-text v-if="userData.lastLogin!=null" class="text-subtitle-3" style="padding: 10px; font-size: 13px; line-height: 15px;">
                Last Login<br>: {{ userData.lastLogin }}
              </v-card-text>
              <v-card-text v-if="userData.lastLogin==null" class="text-subtitle-3" style="padding: 10px; font-size: 13px; line-height: 15px;">
                First Login
              </v-card-text>
              <!-- <v-card-text  style="padding: 10px; font-size: 13px; line-height: 15px;">
                Validation<br>: {{ userData.check }}
              </v-card-text> -->
              <v-divider></v-divider>
              <v-list-item :to="'/settings'" @click="show=false">
                <v-list-item-icon>
                  <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Settings</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-btn icon small v-if="!isMobile" @click.stop="drawer = !drawer">
          <img src="@/assets/menu.svg">
        </v-btn>
      </div>
      
      <v-btn icon small v-if="!isMobile && !isLogged" @click.stop="drawer = !drawer" style="position: absolute; right: 20px;">
        <img src="@/assets/menu.svg">
      </v-btn>
    </v-app-bar>
    <v-main>
        <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import EventBus from './plugins/EventBus';

export default {
  name: 'App',
  data() {
    return {
      drawer: false,
      icons: [
        {
          icon: 'mdi-facebook',
          link: 'https://www.facebook.com/people/Wiki-Security-INC/100064205460242/',
          color: 'indigo'
        },
        {
          icon: 'mdi-youtube',
          link: 'https://www.youtube.com/channel/UCddHTMigvEFbI8Zu29ODwAQ?view_as=subscriber',
          color: 'red'
        },
        {
          icon: 'mdi-linkedin',
          link: 'https://in.linkedin.com/company/wiki-security',
          color: 'blue'
        },
      ],
      items: [
        { title: 'INTRODUCTION', icon: 'mdi-information', href: '/' },
        { title: 'LOGIN', icon: 'mdi-login', href: '/login', show: true },
        { title: 'SCANNER', icon: 'mdi-video', href: '/scan', show: false }, 
        { title: 'SCAN HISTORY', icon: 'mdi-history', href: '/history', show: false }
      ],
      show: false,
      inactivityTimer: null,
      lastActivityTime: new Date().getTime(),
      logoutTimeout: 3600000,
      localStorageKey: 'lastActivityTime'
    };
  },
  computed: {
    isLogged() {
      return this.$store.state.isLogged;
    },
    filteredItems() {
      if (this.isLogged) {
        return this.items.filter(item => !item.show || item.href == '/');
      } else {
        return this.items.filter(item => item.show || item.href == '/');
      }
    },
    userData() {
      return this.$store.state.userData;
    },
    isMobile(){
      var display=false;
      switch(this.$vuetify.breakpoint.name){
        case `md`: display=true; break;
        case `lg`: display=true; break;
        case `xl`: display=true; break;
      }
      return display;
    }
  },
  methods: {
    logout() {
      EventBus.$emit('stopScan');
      axios.post(`${this.$apiUrl}/user/logout`, {}, { withCredentials: true })
        .then((res) => {
          if (res.status === 200) {
            this.$store.dispatch('logout').then(() => {
              if (this.$route.path !== '/login') {
                this.$router.push('/login');
              }
            });
          }
        }).catch(error => {
          console.error("Logout error: ", error);
        });
    },
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimer);
      this.inactivityTimer = setTimeout(() => {
        this.logout();
      }, this.logoutTimeout);
      this.lastActivityTime = new Date().getTime();
      localStorage.setItem(this.localStorageKey, this.lastActivityTime);
    },
    handlePageUnload() {
      localStorage.setItem(this.localStorageKey, new Date().getTime());
    },
    checkForInactivity() {
      const lastActivity = localStorage.getItem(this.localStorageKey);
      if (lastActivity) {
        const timeSinceLastActivity = new Date().getTime() - parseInt(lastActivity);
        if (timeSinceLastActivity >= this.logoutTimeout) {
          this.logout();
        }
      }
    },
    handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        this.checkForInactivity();
      }
    }
  },
  mounted() {
    this.checkForInactivity();
    window.addEventListener('mousemove', this.resetInactivityTimer);
    window.addEventListener('keypress', this.resetInactivityTimer);
    window.addEventListener('click', this.resetInactivityTimer);
    window.addEventListener('beforeunload', this.handlePageUnload);
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    this.resetInactivityTimer();
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.resetInactivityTimer);
    window.removeEventListener('keypress', this.resetInactivityTimer);
    window.removeEventListener('click', this.resetInactivityTimer);
    window.removeEventListener('beforeunload', this.handlePageUnload);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }
}
</script>

<style>
.v-application--wrap{
  height: 100vh;
}
.v-main{
  background-color: #fcfcfc;
  height: 100%;
}
.v-toolbar__content {
  height: 48px;
  position: relative;
}
#main-bar {
  left: 0 !important;
}
.home-btn {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
  min-width: auto !important;
}

</style>
